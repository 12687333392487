//
// Google font - Roboto
//

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');

@font-face {
  font-family: "Montserrat-bold";
  src: local("Montserrat-bold"), url(./../../../fonts/Montserrat-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-medium";
  src: local("Montserrat-medium"), url(./../../../fonts/Montserrat-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-regular";
  src: local("Montserrat-regular"), url(./../../../fonts/Montserrat-Regular.ttf) format("truetype");
}

