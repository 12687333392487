//
// _buttons.scss
//

button,a {
  outline: none !important;
}


// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark, .btn-secondary{
  color: $gray-200 !important;
}

.btn-outline-light{
  color: $gray-900;
}
.btn-primary-aima {
  background-color: white !important;
  color: #131313 !important;
  border-radius: 2px !important;
  border-color: #6E6E73 !important;
}
.btn-primary-aima:hover {
  background-color: white !important;
  border-color: #EB9811 !important;
}
.btn-primary-aima:focus{
  background-color: white !important;
  border-color: #EB9811 !important;
}
.btn-primary-aima:active{
  background-color: white !important;
  border-color: #EB9811 !important;
}
.btn-active{
  background-color: white !important;
  border-color: #EB9811 !important;
}
.btn-plain-aima {
  background-color: #FFFFFF !important;
  color: #333333 !important;
  border-color: #333333 !important;
  border-radius: 2px;
}
.btn-delete-aima {
  background-color: #FFFFFF !important;
  color: #C1290E !important;
  border-color: #C1290E !important;
  border-radius: 2px;
}
.btn-transparent-aima {
  background-color: #ffffff !important;
  color: #333333 !important;
}
