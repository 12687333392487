@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

/* DO NOT REMOVE THIS */
.bg-aima {
  background-color: white;
}

.bg-selector-aima {
  background-color: #d9d9d9;
}

.bg-secondary-aima {
  background-color: #08242c !important;
}

.bg-header {
  background-color: white;
}

.border-aima {
  border: solid 1px #DDDDDD;
}

.text-regular-aima {
  font-family: "Inter", sans-serif !important;
  font-weight: 400 !important;
  color: black !important;
}

.text-footer-aima {
  font-family: "Inter", sans-serif !important;
  font-weight: 400 !important;
}

.text-regular-white-aima {
  font-family: "Inter", sans-serif !important;
  font-weight: 400 !important;
  color: white !important;
}

.text-medium-aima {
  font-family: "Inter", sans-serif !important;
  font-weight: 500 !important;
  color: black !important;
}

.text-semi-bold-aima {
  font-family: "Inter", sans-serif !important;
  font-weight: 600 !important;
  color: black !important;
}

.text-semi-bold-white-aima {
  font-family: "Inter", sans-serif !important;
  font-weight: 600 !important;
  color: white !important;
}

.text-bold-aima {
  font-family: "Inter", sans-serif !important;
  font-weight: bold !important;
  color: black !important;
}

.btn-primary-aima {
  background-color: white !important;
  color: #131313 !important;
  border-radius: 1px !important;
  border-color: #6E6E73 !important;
}

* {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.ps {
  background-color: white !important;
}

.footer {
  background-color: white !important;
}

.navbar-header {
  background-color: white !important;
}

.navbar-brand-box {
  background-color: white !important;
}

#sidebar-menu {
  background-color: white !important;
}

.custom-ng-select {
  width: 200px !important;
}

.ng-select.custom-ng-select ::ng-deep .ng-dropdown-panel .ng-option.ng-option-marked {
  background-color: #343a40 !important;
  color: #fff !important;
}

.ng-select.custom-ng-select.ng-select-multiple ::ng-deep .ng-select-container .ng-value-container .ng-value {
  background-color: #343a40 !important;
  color: #fff !important;
}

.custom-input {
  width: 150px !important;
}

.title-page {
  font-size: 18px !important;
  color: #131313 !important;
  font-weight: 600 !important;
}

.subtitle {
  font-size: 16px !important;
  color: #131313 !important;
  font-weight: 400 !important;
}

.textGeneral {
  font-size: 12px !important;
  color: #616161 !important;
}

.p-datatable .p-datatable-header {
  background: transparent !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #616161 !important;
  color: #FAFAFA !important;
  border-color: #616161 !important;
}

.p-datatable .p-datatable-thead > tr > th {
  background: transparent !important;
}

.input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child) {
  height: 26px !important;
}

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn, .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  height: 26px !important;
}

.ng-select .ng-select-container {
  border-radius: 2px !important;
}

.input-group-text {
  background-color: transparent !important;
}

.sub-subtitle {
  font-size: 14px;
  font-weight: 600 !important;
}

.p-datatable .p-datatable-header {
  border: none !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.k-button-solid-primary {
  border-color: #6E6E73 !important;
  background-color: white !important;
  border-radius: 1px !important;
  color: #131313 !important;
}

.k-grid-save-command {
  border-color: #6E6E73 !important;
  background-color: white !important;
  border-radius: 1px !important;
  color: #131313 !important;
}

.k-grid-cancel-command {
  border-color: #6E6E73 !important;
  background-color: white !important;
  border-radius: 1px !important;
  color: #131313 !important;
}

.k-grid-remove-command {
  border-color: #6E6E73 !important;
  background-color: white !important;
  border-radius: 1px !important;
  color: #131313 !important;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: #000000 !important;
  background-color: #000000 !important;
}

.k-checkbox:checked:focus, .k-checkbox.k-checked.k-focus {
  box-shadow: none !important;
}

.k-toolbar {
  background-color: transparent !important;
}

.k-grid .k-grid-header .k-filterable > .k-cell-inner .k-link {
  background-color: transparent !important;
}

.k-grid-header .k-grid-filter.k-active, .k-grid-header .k-header-column-menu.k-active, .k-grid-header .k-grid-header-menu.k-active, .k-grid-header .k-hierarchy-cell .k-icon.k-active, .k-grid-header .k-hierarchy-cell .k-svg-icon.k-active {
  color: #eb9811 !important;
  background-color: #f9f9f9 !important;
}

.k-grid-content {
  background-color: transparent !important;
}

.k-grid-header, .k-grouping-header, .k-grid-add-row, .k-grid-footer {
  color: #424242;
  background-color: transparent !important;
}

.k-grid .k-table-th {
  background-color: transparent !important;
}

.k-grid .k-grid-header .k-grid-filter, .k-grid .k-grid-header .k-header-column-menu, .k-grid .k-grid-header .k-grid-header-menu {
  background-color: transparent;
}

.k-grid .k-table-th, .k-grid td, .k-grid .k-table-td {
  background-color: transparent !important;
}

.k-listbox .k-drop-hint {
  border-top-color: blue !important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #000000 !important;
  background-color: #fff !important;
  text-decoration: underline !important;
  text-decoration-color: #000000 !important;
  text-decoration-thickness: 1px !important;
  border-color: #fff #fff #fff !important;
}

.nav-tabs .nav-link {
  color: #8D9096 !important;
  border: none !important;
  text-decoration: underline !important;
  text-decoration-color: #8D9096 !important;
  text-decoration-thickness: 1px !important;
  text-underline-offset: 0.5em !important;
}

.nav-tabs {
  border-bottom: none !important;
  margin-left: -14px !important;
}

.page-item.active .page-link {
  font-size: 12px;
  font-weight: bold;
  color: black !important;
  background-color: transparent !important;
  border-color: transparent !important;
  margin-top: -1px;
}

.page-link {
  font-size: 12px;
  color: black !important;
  background-color: transparent !important;
  border: none !important;
}

.hr-tab {
  margin-top: -1px;
  background-color: #8d9096;
  width: 69px;
  height: 2px;
}

.hr-tab.active {
  background-color: black;
}

.subtitle {
  font-size: 13px;
}

.underline {
  margin: 0 !important;
  font-weight: bold;
}

.divisor {
  background-color: red;
}

.hr-subtitle {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #343a40;
  margin: 0;
  padding: 0;
}

.kendo {
  padding: 12px;
}

.k-column-title {
  font-weight: 600 !important;
  color: #131313 !important;
}

.k-button-text {
  font-size: 0.7109375rem !important;
}

.k-table-md {
  font-size: 12px !important;
}

th {
  font-size: 12px !important;
  color: #131313 !important;
}

td {
  font-size: 12px !important;
  color: #6E6E73 !important;
}

.k-pager-info {
  font-size: 12px !important;
}

nav-tabs .nav-link {
  margin-left: -14px !important;
}

.k-input, .k-picker {
  font-size: 12px !important;
}

.quickview .modal.left .modal-dialog,
.quickview .modal.right .modal-dialog {
  position: absolute;
  margin: auto;
  margin-right: 320px;
  width: 900px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}
@media (min-width: 576px) {
  .quickview .modal-dialog {
    max-width: 1500px !important;
  }
}
.quickview .modal.left .modal-content,
.quickview .modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}
.quickview .modal.left .modal-body,
.quickview .modal.right .modal-body {
  padding: 15px 15px 80px;
}
.quickview .modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}
.quickview .modal.right.fade.in .modal-dialog {
  right: 0;
}
.quickview .modal-content {
  border-radius: 0;
  border: none;
}
.quickview .modal-header {
  border-bottom-color: #EEEEEE;
  background-color: white;
}

.page-template {
  font-family: "DejaVu Sans", "Arial", sans-serif;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.page-template .header {
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  border-bottom: 1px solid #888;
  color: #888;
}

.page-template .footer {
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  border-top: 1px solid #888;
  text-align: center;
  color: #888;
}

.file-success {
  color: #4CA459;
}

.file-error {
  color: #DA1212;
}

.treelist-item {
  font-size: 12px;
  font-family: Inter;
}