/*
Template Name: Skote - Responsive Bootstrap 4 Admin Dashboard
Author: Themesbrand
Version: 1.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import "@fullcalendar/core/main.css";
@import "@fullcalendar/daygrid/main.css";
@import "@fullcalendar/timegrid/main.css";
@import "@fullcalendar/bootstrap/main.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~dropzone/dist/min/dropzone.min.css';
@import "~angular-archwizard/archwizard.css";
@import '~chartist/dist/chartist.css';
@font-face {
  font-family: "Montserrat-bold";
  src: local("Montserrat-bold"), url(./../../../fonts/Montserrat-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-medium";
  src: local("Montserrat-medium"), url(./../../../fonts/Montserrat-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-regular";
  src: local("Montserrat-regular"), url(./../../../fonts/Montserrat-Regular.ttf) format("truetype");
}
#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  background-color: #FAFAFA;
}

.navbar-header {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 70px;
  padding: 0 calc(24px / 2) 0 0;
}
.navbar-header .dropdown.show .header-item {
  background-color: #f8f9fa;
}

.navbar-brand-box {
  background-color: #F5F5F5 !important;
  padding: 0 1.5rem;
  text-align: center;
  width: 250px;
}

.logo .logo-sm {
  display: none;
}

.logo-light {
  display: block;
}
/* Search */
.app-search {
  padding: calc(32px / 2) 0;
}
.app-search .form-control {
  border: none;
  height: 38px;
  padding-left: 40px;
  padding-right: 20px;
  background-color: #f3f3f9;
  box-shadow: none;
  border-radius: 30px;
}
.app-search span {
  position: absolute;
  z-index: 10;
  font-size: 16px;
  line-height: 38px;
  left: 13px;
  top: 0;
  color: #74788d;
}

.megamenu-list li {
  position: relative;
  padding: 5px 0px;
}
.megamenu-list li a {
  color: #495057;
}

@media (max-width: 992px) {
  .navbar-brand-box {
    width: auto;
  }
  .logo span.logo-lg {
    display: none;
  }
  .logo span.logo-sm {
    display: inline-block;
  }
}
.page-content {
  padding: calc(70px + 24px) calc(24px / 2) 60px calc(24px / 2);
}

.header-item {
  height: 70px;
  box-shadow: none !important;
  color: black;
  border: 0;
  border-radius: 0;
}
.header-item:hover {
  color: black;
}

.header-profile-user {
  height: 36px;
  width: 36px;
  background-color: #f6f6f6;
  padding: 3px;
}

.noti-icon i {
  font-size: 22px;
  color: black;
}
.noti-icon .badge {
  position: absolute;
  top: 12px;
  margin-left: -10px;
}

.notification-item .media {
  padding: 0.75rem 1rem;
}
.notification-item .media:hover {
  background-color: #f6f6f6;
}

.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  border: 1px solid transparent;
  color: #74788d;
}
.dropdown-icon-item img {
  height: 24px;
}
.dropdown-icon-item span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown-icon-item:hover {
  border-color: #eff2f7;
}

.fullscreen-enable [data-toggle=fullscreen] .bx-fullscreen::before {
  content: "\ea3f";
}

body[data-topbar=dark] #page-topbar {
  background-color: #2a3042;
}
body[data-topbar=dark] .navbar-header .dropdown.show .header-item {
  background-color: rgba(255, 255, 255, 0.05);
}
body[data-topbar=dark] .navbar-header .waves-effect .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
}
body[data-topbar=dark] .header-item {
  color: #e9ecef;
}
body[data-topbar=dark] .header-item:hover {
  color: #e9ecef;
}
body[data-topbar=dark] .header-profile-user {
  background-color: rgba(255, 255, 255, 0.25);
}
body[data-topbar=dark] .noti-icon i {
  color: #e9ecef;
}
body[data-topbar=dark] .logo-dark {
  display: none;
}
body[data-topbar=dark] .logo-light {
  display: block;
}
body[data-topbar=dark] .app-search .form-control {
  background-color: rgba(243, 243, 249, 0.07);
  color: #fff;
}
body[data-topbar=dark] .app-search span,
body[data-topbar=dark] .app-search input.form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

body[data-sidebar=dark] .navbar-brand-box {
  background: #FAFAFA;
}
body[data-sidebar=dark] .logo-dark {
  display: none;
}
body[data-sidebar=dark] .logo-light {
  display: block;
}

@media (max-width: 600px) {
  .navbar-header .dropdown {
    position: static;
  }
  .navbar-header .dropdown .dropdown-menu {
    left: 10px !important;
    right: 10px !important;
  }
}
@media (max-width: 380px) {
  .navbar-brand-box {
    display: none;
  }
}
body[data-topbar=dark] #page-topbar {
  background-color: #2a3042;
}
body[data-topbar=dark] .navbar-header .dropdown.show .header-item {
  background-color: rgba(255, 255, 255, 0.05);
}
body[data-topbar=dark] .navbar-header .waves-effect .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
}
body[data-topbar=dark] .header-item {
  color: #e9ecef;
}
body[data-topbar=dark] .header-item:hover {
  color: #e9ecef;
}
body[data-topbar=dark] .header-profile-user {
  background-color: rgba(255, 255, 255, 0.25);
}
body[data-topbar=dark] .noti-icon i {
  color: #e9ecef;
}
body[data-topbar=dark] .logo-dark {
  display: none;
}
body[data-topbar=dark] .logo-light {
  display: block;
}
body[data-topbar=dark] .app-search .form-control {
  background-color: rgba(243, 243, 249, 0.07);
  color: #fff;
}
body[data-topbar=dark] .app-search span,
body[data-topbar=dark] .app-search input.form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

body[data-sidebar=dark] .navbar-brand-box {
  background: #FAFAFA;
}
body[data-sidebar=dark] .logo-dark {
  display: none;
}
body[data-sidebar=dark] .logo-light {
  display: block;
}

@media (max-width: 600px) {
  .navbar-header .dropdown {
    position: static;
  }
  .navbar-header .dropdown .dropdown-menu {
    left: 10px !important;
    right: 10px !important;
  }
}
@media (max-width: 380px) {
  .navbar-brand-box {
    display: none;
  }
}
body[data-layout=horizontal] .navbar-brand-box {
  width: auto;
}
body[data-layout=horizontal] .page-content {
  margin-top: 70px;
  padding: calc(55px + 24px) calc(24px / 2) 60px calc(24px / 2);
}

@media (max-width: 992px) {
  body[data-layout=horizontal] .page-content {
    margin-top: 15px;
  }
}
.page-title-box {
  padding-bottom: 24px;
}
.page-title-box .breadcrumb {
  background-color: transparent;
  padding: 0;
}
.page-title-box h4 {
  font-weight: 600;
  font-size: 16px !important;
}

.footer {
  bottom: 0;
  padding: 20px calc(24px / 2);
  position: absolute;
  right: 0;
  color: #74788d;
  left: 250px;
  height: 60px;
  background-color: #fafafa;
}

@media (max-width: 992px) {
  .footer {
    left: 0;
  }
}
.vertical-collpsed .footer {
  left: 70px;
}

body[data-layout=horizontal] .footer {
  left: 0 !important;
}

.right-bar {
  background-color: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  display: block;
  position: fixed;
  transition: all 200ms ease-out;
  width: 280px;
  z-index: 9999;
  float: right !important;
  right: -290px;
  top: 0;
  bottom: 0;
}
.right-bar .right-bar-toggle {
  background-color: #444c54;
  height: 24px;
  width: 24px;
  line-height: 24px;
  color: #eff2f7;
  text-align: center;
  border-radius: 50%;
}
.right-bar .right-bar-toggle:hover {
  background-color: #4b545c;
}

.rightbar-overlay {
  background-color: rgba(52, 58, 64, 0.55);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 9998;
  transition: all 0.2s ease-out;
}

.right-bar-enabled .right-bar {
  right: 0;
}
.right-bar-enabled .rightbar-overlay {
  display: block;
}

@media (max-width: 767.98px) {
  .right-bar {
    overflow: auto;
  }
  .right-bar .slimscroll-menu {
    height: auto !important;
  }
}
.metismenu {
  margin: 0;
}
.metismenu li {
  display: block;
  width: 100%;
}
.metismenu .mm-collapse {
  display: none;
}
.metismenu .mm-collapse:not(.mm-show) {
  display: none;
}
.metismenu .mm-collapse.mm-show {
  display: block;
}
.metismenu .mm-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: 0.35s;
  transition-property: height, visibility;
}

.vertical-menu {
  width: 250px;
  z-index: 1001;
  background: #fafafa !important;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 70px;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.main-content {
  margin-left: 250px;
  overflow: hidden;
}
.main-content .content {
  padding: 0 15px 10px 15px;
  margin-top: 70px;
}

@media (max-width: 992px) {
  .main-content {
    margin-left: 0;
  }
}
#sidebar-menu {
  padding: 10px 0 30px 0;
  margin-top: 17%;
  min-height: 80vh !important;
}
#sidebar-menu .mm-active > .has-arrow:after {
  transform: rotate(-180deg);
}
#sidebar-menu .has-arrow:after {
  content: "\f0140";
  font-family: "Material Design Icons";
  display: block;
  float: right;
  transition: transform 0.2s;
  font-size: 1rem;
}
#sidebar-menu ul li a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}
#sidebar-menu ul li a i {
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}
#sidebar-menu ul li a:hover {
  color: #383c40;
}
#sidebar-menu ul li a:hover i {
  color: #383c40;
}
#sidebar-menu ul li .badge {
  margin-top: 4px;
}
#sidebar-menu ul li ul.sub-menu {
  padding: 0;
}
#sidebar-menu ul li ul.sub-menu li a {
  padding: 0.4rem 1.5rem 0.4rem 3.5rem;
  font-size: 13px;
  color: #545a6d;
}
#sidebar-menu ul li ul.sub-menu li ul.sub-menu {
  padding: 0;
}
#sidebar-menu ul li ul.sub-menu li ul.sub-menu li a {
  padding: 0.4rem 1.5rem 0.4rem 4.5rem;
  font-size: 13.5px;
}

.menu-title {
  padding: 12px 20px !important;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: #7f8387;
  font-weight: 600;
}

.mm-active {
  color: black !important;
}
.mm-active .active {
  color: black !important;
}
.mm-active .active i {
  color: black !important;
}
.mm-active > i {
  color: black !important;
}

@media (max-width: 992px) {
  .vertical-menu {
    display: none;
  }
  body.sidebar-enable .vertical-menu {
    background: #010c13;
    display: block;
  }
}
.vertical-collpsed {
  background: #fafafa !important;
}
.vertical-collpsed .main-content {
  margin-left: 70px;
}
.vertical-collpsed .navbar-brand-box {
  width: 70px !important;
}
.vertical-collpsed .logo span.logo-lg {
  display: none;
}
.vertical-collpsed .logo span.logo-sm {
  display: block;
}
.vertical-collpsed .vertical-menu {
  position: absolute;
  width: 70px !important;
  z-index: 5;
}
.vertical-collpsed .vertical-menu perfect-scrollbar {
  overflow: visible;
}
.vertical-collpsed .vertical-menu perfect-scrollbar .ps {
  overflow: visible !important;
}
.vertical-collpsed .vertical-menu .simplebar-mask,
.vertical-collpsed .vertical-menu .simplebar-content-wrapper {
  overflow: visible !important;
}
.vertical-collpsed .vertical-menu .simplebar-scrollbar {
  display: none !important;
}
.vertical-collpsed .vertical-menu .simplebar-offset {
  bottom: 0 !important;
}
.vertical-collpsed .vertical-menu #sidebar-menu {
  background: #fafafa;
}
.vertical-collpsed .vertical-menu #sidebar-menu .menu-title,
.vertical-collpsed .vertical-menu #sidebar-menu .badge,
.vertical-collpsed .vertical-menu #sidebar-menu .collapse.in {
  display: none !important;
}
.vertical-collpsed .vertical-menu #sidebar-menu .nav.collapse {
  height: inherit !important;
}
.vertical-collpsed .vertical-menu #sidebar-menu .has-arrow:after {
  display: none;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li {
  position: relative;
  white-space: nowrap;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a {
  padding: 15px 20px;
  min-height: 55px;
  transition: none;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:hover, .vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:active, .vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:focus {
  background-color: #fafafa;
  color: #383c40;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a i {
  font-size: 1.45rem;
  margin-left: 4px;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a span {
  display: none;
  padding-left: 25px;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover {
  background: #fafafa;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
  background-color: white;
  position: relative;
  width: calc(190px + 70px);
  color: #556ee6;
  transition: none;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a i {
  color: #556ee6;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a span {
  display: inline;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul {
  display: block;
  left: 70px;
  position: absolute;
  width: 190px;
  height: auto !important;
  background-color: white;
  box-shadow: 3px 5px 10px 0 white;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul ul {
  background-color: #fafafa;
  box-shadow: 3px 5px 10px 0 white;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a {
  box-shadow: none;
  padding: 8px 20px;
  position: relative;
  width: 190px;
  z-index: 6;
  color: #545a6d;
  background: white;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a:hover {
  background: #f5f5f7;
  color: #383c40;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul ul {
  padding: 5px 0;
  z-index: 9999;
  display: none;
  background-color: #ffffff;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul ul li:hover > ul {
  display: block;
  left: 190px;
  height: auto !important;
  margin-top: -36px;
  position: absolute;
  width: 190px;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul ul li > a span.pull-right {
  position: absolute;
  right: 20px;
  top: 12px;
  transform: rotate(270deg);
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul ul li.active a {
  color: #f8f9fa;
}

body[data-sidebar=dark] #sidebar-menu ul li a {
  color: #707070;
}
body[data-sidebar=dark] #sidebar-menu ul li a i {
  color: #707070;
}
body[data-sidebar=dark] #sidebar-menu ul li a:hover {
  color: black;
}
body[data-sidebar=dark] #sidebar-menu ul li a:hover i {
  color: black;
}
body[data-sidebar=dark] #sidebar-menu ul li ul.sub-menu li a {
  color: #707070;
}
body[data-sidebar=dark] #sidebar-menu ul li ul.sub-menu li a:hover {
  color: black;
}
body[data-sidebar=dark].vertical-collpsed {
  min-height: 100vh;
}
body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
  color: black;
}
body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a i {
  color: black;
}
body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a {
  color: #707070;
}
body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a:hover {
  color: black;
}
body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu > ul ul {
  background-color: white;
}
body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active {
  color: black !important;
}
body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active i {
  color: black !important;
}
body[data-sidebar=dark] .mm-active {
  color: black !important;
}
body[data-sidebar=dark] .mm-active > i {
  color: black !important;
}
body[data-sidebar=dark] .mm-active .active {
  color: black !important;
}
body[data-sidebar=dark] .mm-active .active i {
  color: black !important;
}
body[data-sidebar=dark] .menu-title {
  color: #707070;
}

body[data-layout=horizontal] .main-content {
  margin-left: 0 !important;
}

body[data-sidebar-size=small] .navbar-brand-box {
  width: 160px;
}
body[data-sidebar-size=small] .vertical-menu {
  width: 160px;
  text-align: center;
}
body[data-sidebar-size=small] .vertical-menu .has-arrow:after,
body[data-sidebar-size=small] .vertical-menu .badge {
  display: none !important;
}
body[data-sidebar-size=small] .main-content {
  margin-left: 160px;
}
body[data-sidebar-size=small] .footer {
  left: 160px;
}
body[data-sidebar-size=small] #sidebar-menu {
  background: #010c13;
}
body[data-sidebar-size=small] #sidebar-menu ul li a i {
  display: block;
}
body[data-sidebar-size=small] #sidebar-menu ul li ul.sub-menu li a {
  padding-left: 1.5rem;
}
body[data-sidebar-size=small].vertical-collpsed .main-content {
  margin-left: 70px;
}
body[data-sidebar-size=small].vertical-collpsed .vertical-menu #sidebar-menu {
  text-align: left;
}
body[data-sidebar-size=small].vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a i {
  display: inline-block;
}
body[data-sidebar-size=small].vertical-collpsed .footer {
  left: 70px;
}

body[data-sidebar=colored] .vertical-menu {
  background-color: #556ee6;
}
body[data-sidebar=colored] .navbar-brand-box {
  background-color: #556ee6;
}
body[data-sidebar=colored] .navbar-brand-box .logo-dark {
  display: none;
}
body[data-sidebar=colored] .navbar-brand-box .logo-light {
  display: block;
}
body[data-sidebar=colored] .mm-active {
  color: #fff !important;
}
body[data-sidebar=colored] .mm-active > i,
body[data-sidebar=colored] .mm-active .active {
  color: #fff !important;
}
body[data-sidebar=colored] #sidebar-menu ul li.menu-title {
  color: rgba(255, 255, 255, 0.6);
}
body[data-sidebar=colored] #sidebar-menu ul li a {
  color: rgba(255, 255, 255, 0.5);
}
body[data-sidebar=colored] #sidebar-menu ul li a i {
  color: rgba(255, 255, 255, 0.5);
}
body[data-sidebar=colored] #sidebar-menu ul li a.waves-effect .waves-ripple {
  background: rgba(255, 255, 255, 0.1);
}
body[data-sidebar=colored] #sidebar-menu ul li ul.sub-menu li a {
  color: rgba(255, 255, 255, 0.4);
}
body[data-sidebar=colored].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
  background-color: #5e76e7;
}
body[data-sidebar=colored].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active {
  background: #010c13;
}

.topnav {
  background: #fff;
  padding: 0 calc(24px / 2);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  margin-top: 70px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
}
.topnav .topnav-menu {
  margin: 0;
  padding: 0;
}
.topnav .navbar-nav .nav-link {
  font-size: 14px;
  position: relative;
  padding: 1rem 1.3rem;
  color: #545a6d;
}
.topnav .navbar-nav .nav-link i {
  font-size: 15px;
}
.topnav .navbar-nav .nav-link:focus, .topnav .navbar-nav .nav-link:hover {
  color: black;
  background-color: transparent;
}
.topnav .navbar-nav .dropdown-item {
  color: #545a6d;
}
.topnav .navbar-nav .dropdown-item.active, .topnav .navbar-nav .dropdown-item:hover {
  color: black;
}
.topnav .navbar-nav .nav-item .nav-link.active {
  color: black;
}
.topnav .navbar-nav .dropdown.active > a {
  color: black;
  background-color: transparent;
}

@media (min-width: 1200px) {
  body[data-layout=horizontal] .container-fluid,
  body[data-layout=horizontal] .navbar-header {
    max-width: 85%;
  }
}
@media (min-width: 992px) {
  .topnav .navbar-nav .nav-item:first-of-type .nav-link {
    padding-left: 0;
  }
  .topnav .dropdown-item {
    padding: 0.5rem 1.5rem;
    min-width: 180px;
  }
  .topnav .dropdown.mega-dropdown .mega-dropdown-menu {
    left: 0px;
    right: auto;
  }
  .topnav .dropdown .dropdown-menu {
    margin-top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
  }
  .topnav .dropdown .dropdown-menu .arrow-down::after {
    right: 15px;
    transform: rotate(-135deg) translateY(-50%);
    position: absolute;
  }
  .topnav .dropdown .dropdown-menu .dropdown .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
    display: none;
  }
  .topnav .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .topnav .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .navbar-toggle {
    display: none;
  }
}
.arrow-down {
  display: inline-block;
}
.arrow-down:after {
  border-color: initial;
  border-style: solid;
  border-width: 0 0 1px 1px;
  content: "";
  height: 0.4em;
  display: inline-block;
  right: 5px;
  top: 50%;
  margin-left: 10px;
  transform: rotate(-45deg) translateY(-50%);
  transform-origin: top;
  transition: all 0.3s ease-out;
  width: 0.4em;
}

@media (max-width: 1199.98px) {
  .topnav-menu .navbar-nav li:last-of-type .dropdown .dropdown-menu {
    right: 100%;
    left: auto;
  }
}
@media (max-width: 991.98px) {
  .topnav {
    max-height: 360px;
    overflow-y: auto;
    padding: 0;
  }
  .topnav .navbar-nav .nav-link {
    padding: 0.75rem 1.1rem;
  }
  .topnav .dropdown .dropdown-menu {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding-left: 15px;
  }
  .topnav .dropdown .dropdown-item {
    position: relative;
    background-color: transparent;
  }
  .topnav .dropdown .dropdown-item.active, .topnav .dropdown .dropdown-item:active {
    color: #556ee6;
  }
  .topnav .arrow-down::after {
    right: 15px;
    position: absolute;
  }
}
@media (min-width: 992px) {
  body[data-layout=horizontal][data-topbar=light] .topnav {
    background-color: #556ee6;
  }
  body[data-layout=horizontal][data-topbar=light] .topnav .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  body[data-layout=horizontal][data-topbar=light] .topnav .navbar-nav .nav-link:focus, body[data-layout=horizontal][data-topbar=light] .topnav .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.9);
  }
  body[data-layout=horizontal][data-topbar=light] .topnav .navbar-nav > .dropdown.active > a {
    color: rgba(255, 255, 255, 0.9) !important;
  }
}
body[data-layout=horizontal][data-topbar=colored] #page-topbar {
  background-color: #556ee6;
  box-shadow: none;
}
body[data-layout=horizontal][data-topbar=colored] .logo-dark {
  display: none;
}
body[data-layout=horizontal][data-topbar=colored] .logo-light {
  display: block;
}
body[data-layout=horizontal][data-topbar=colored] .app-search .form-control {
  background-color: rgba(243, 243, 249, 0.07);
  color: #fff;
}
body[data-layout=horizontal][data-topbar=colored] .app-search span,
body[data-layout=horizontal][data-topbar=colored] .app-search input.form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
body[data-layout=horizontal][data-topbar=colored] .header-item {
  color: #e9ecef;
}
body[data-layout=horizontal][data-topbar=colored] .header-item:hover {
  color: #e9ecef;
}
body[data-layout=horizontal][data-topbar=colored] .navbar-header .dropdown.show .header-item {
  background-color: rgba(255, 255, 255, 0.1);
}
body[data-layout=horizontal][data-topbar=colored] .navbar-header .waves-effect .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
}
body[data-layout=horizontal][data-topbar=colored] .noti-icon i {
  color: #e9ecef;
}
@media (min-width: 992px) {
  body[data-layout=horizontal][data-topbar=colored] .topnav {
    background-color: #556ee6;
  }
  body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav .nav-link:focus, body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.9);
  }
  body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav > .dropdown.active > a {
    color: rgba(255, 255, 255, 0.9) !important;
  }
}

body[data-layout-size=boxed] {
  background-color: #ebebf4;
}
body[data-layout-size=boxed] #layout-wrapper {
  max-width: 1300px;
  margin: 0 auto;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
body[data-layout-size=boxed] #page-topbar {
  max-width: 1300px;
  margin: 0 auto;
}
body[data-layout-size=boxed] .footer {
  margin: 0 auto;
  max-width: calc(1300px - 250px);
}
body[data-layout-size=boxed].vertical-collpsed .footer {
  max-width: calc(1300px - 70px);
}

body[data-layout=horizontal][data-layout-size=boxed] #page-topbar, body[data-layout=horizontal][data-layout-size=boxed] #layout-wrapper, body[data-layout=horizontal][data-layout-size=boxed] .footer {
  max-width: 100%;
}
body[data-layout=horizontal][data-layout-size=boxed] .container-fluid, body[data-layout=horizontal][data-layout-size=boxed] .navbar-header {
  max-width: 1300px;
}

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  width: 6px !important;
  background-color: transparent !important;
}

.ps__thumb-y {
  background-color: #a2adb7 !important;
}

perfect-scrollbar > .ps.ps--active-y > .ps__rail-y:hover {
  width: auto !important;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  background-color: transparent !important;
}

/* ==============
  Calendar
===================*/
.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px;
  padding: 4px 4px;
  text-align: center;
}

.app-calendar .btn {
  text-transform: capitalize;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-view {
  margin-top: 30px;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar {
  margin: 10px 0 5px 0;
}
.fc-toolbar h2 {
  font-size: 1.25rem;
  line-height: 1.875rem;
  text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
  font-weight: 500;
}

th.fc-day-header {
  padding: 0.5rem 0;
}

.fc-day {
  background: transparent;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  background: #f6f6f6;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: 500;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #f6f6f6;
}
.fc-unthemed td.fc-today,
.fc-unthemed .fc-divider {
  background: #f6f6f6;
}

.fc-button {
  background: #f6f6f6;
  border: none;
  color: #495057;
  text-transform: capitalize;
  box-shadow: none;
  border-radius: 3px;
  margin: 0 3px;
  padding: 6px 12px;
  height: auto;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}

.fc-state-hover {
  background: #f6f6f6;
}

.fc-state-highlight {
  background: #f6f6f6;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #556ee6;
  color: #fff;
  text-shadow: none;
}

.fc-cell-overlay {
  background: #f6f6f6;
}

.fc-unthemed .fc-today {
  background: #fff;
}

.external-event {
  cursor: move;
  margin: 10px 0;
  padding: 8px 10px;
  color: #fff;
  border-radius: 4px;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
}
.fc-basic-view td.fc-day-number {
  padding-right: 8px;
}
.fc-basic-view .fc-content {
  color: #fff;
}

.fc-time-grid-event .fc-content {
  color: #fff;
}

.fc-timeGridDay-view .fc-content .fc-time, .fc-timeGridWeek-view .fc-content .fc-time {
  display: inline-block;
}
.fc-timeGridDay-view .fc-content .fc-title, .fc-timeGridWeek-view .fc-content .fc-title {
  display: inline-block;
  margin-left: 5px;
}

@media (max-width: 767.98px) {
  .fc-toolbar {
    display: block;
  }
  .fc-toolbar .fc-left,
  .fc-toolbar .fc-right,
  .fc-toolbar .fc-center {
    float: none;
    display: block;
    clear: both;
    margin: 10px 0;
  }
  .fc .fc-toolbar > * > * {
    float: none;
  }
  .fc-today-button {
    display: none;
  }
}
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: auto !important;
}

/* ==============
  Druafula
===================*/
.task-box {
  border: 1px solid #f6f6f6;
}

.task-placeholder {
  border: 1px dashed #f6f6f6 !important;
  background-color: #fff !important;
  padding: 20px;
  box-shadow: none !important;
}

.gu-transit {
  border: 1px dashed #74788d !important;
  background-color: #eff2f7 !important;
}

.ng5-slider .ng5-slider-pointer {
  background-color: #556ee6 !important;
  width: 18px !important;
  height: 18px !important;
  top: -7px !important;
  outline: none !important;
}
.ng5-slider .ng5-slider-pointer:after {
  top: 5px !important;
  left: 5px !important;
}
.ng5-slider .ng5-slider-active:after {
  background-color: #fff !important;
}
.ng5-slider .ng5-slider-tick {
  left: -3px !important;
}
.ng5-slider .ng5-slider-tick-value {
  left: 1px !important;
  top: -25px !important;
}
.ng5-slider .ng5-slider-tick-legend {
  top: 14px !important;
  text-transform: capitalize;
  font-size: 0.7rem;
}
.ng5-slider .ng5-slider-selection {
  background: #556ee6 !important;
}

.swal2-container .swal2-title {
  font-size: 24px;
  font-weight: 500;
}

.swal2-icon.swal2-question {
  border-color: #50a5f1;
  color: #50a5f1;
}
.swal2-icon.swal2-success [class^=swal2-success-line] {
  background-color: #34c38f;
}
.swal2-icon.swal2-success .swal2-success-ring {
  border-color: rgba(52, 195, 143, 0.3);
}
.swal2-icon.swal2-warning {
  border-color: #f1b44c;
  color: #f1b44c;
}

.swal2-styled:focus {
  box-shadow: none;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #556ee6;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step, .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: rgba(85, 110, 230, 0.3);
}

.rating-symbol-background, .rating-symbol-foreground {
  font-size: 24px;
}

.rating-symbol-foreground {
  top: 0px;
}

.error {
  color: #f46a6a;
}

.parsley-error {
  border-color: #f46a6a;
}

.parsley-errors-list {
  display: none;
  margin: 0;
  padding: 0;
}
.parsley-errors-list.filled {
  display: block;
}
.parsley-errors-list > li {
  font-size: 12px;
  list-style: none;
  color: #f46a6a;
  margin-top: 5px;
}

.ng-select.ng-select-single .ng-select-container {
  height: calc(1.5em + 0.94rem + 2px) !important;
}

.ng-select .ng-select-container {
  border: 1px solid #ced4da !important;
}

.ng-dropdown-panel {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border: none !important;
  z-index: 99 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #EEEEEE !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: none !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #556ee6;
  color: #fff;
}

.select2-container .select2-selection--single {
  background-color: #fff;
  border: 1px solid #ced4da;
  height: 38px;
}
.select2-container .select2-selection--single:focus {
  outline: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 36px;
  padding-left: 12px;
}
.select2-container .select2-selection--single .select2-selection__arrow {
  height: 34px;
  width: 34px;
  right: 3px;
}
.select2-container .select2-selection--single .select2-selection__arrow b {
  border-color: #adb5bd transparent transparent transparent;
  border-width: 6px 6px 0 6px;
}

.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #adb5bd transparent !important;
  border-width: 0 6px 6px 6px !important;
}

.select2-container--default .select2-search--dropdown {
  padding: 10px;
  background-color: #fff;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #ced4da;
  background-color: #fff;
  color: #74788d;
  outline: none;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #556ee6;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #f8f9fa;
  color: #16181b;
}
.select2-container--default .select2-results__option[aria-selected=true]:hover {
  background-color: #556ee6;
  color: #fff;
}

.select2-results__option {
  padding: 6px 12px;
}

.select2-dropdown {
  border: rgba(0, 0, 0, 0.15);
  background-color: #fff;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.select2-search input {
  border: 1px solid #f6f6f6;
}

.select2-container .select2-selection--multiple {
  min-height: 38px;
  background-color: #fff;
  border: 1px solid #ced4da !important;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 2px 10px;
}
.select2-container .select2-selection--multiple .select2-search__field {
  margin-top: 7px;
  border: 0;
}
.select2-container .select2-selection--multiple .select2-selection__choice {
  background-color: #eff2f7;
  border: 1px solid #f6f6f6;
  border-radius: 1px;
  padding: 0 7px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #ced4da;
}

.ng-select .ng-select-container {
  background-color: #fff !important;
  color: #495057 !important;
}
.ng-select .ng-select-container input {
  color: #495057 !important;
}
.ng-select .ng-dropdown-panel .ng-option {
  background-color: #fff !important;
  color: #495057 !important;
}
.ng-select .ng-dropdown-panel .ng-option.ng-option-marked {
  background-color: #556ee6 !important;
  color: #fff !important;
}

.switch.switch-small {
  min-width: 33px;
}

.switch.switch-medium {
  min-width: 50px;
}

.switch.switch-large {
  min-width: 60px;
}

.switch.switch-small > .switch-pane > span {
  font-size: 9px;
}

.switch.switch-medium > .switch-pane > span {
  font-size: 16px;
}

.switch.switch-large > .switch-pane > span {
  font-size: 16px;
}

.switch {
  border: 1px solid #dfdfdf;
  position: relative;
  display: inline-block;
  box-sizing: content-box;
  padding: 0;
  margin: 0;
  cursor: pointer;
  box-shadow: rgb(223, 223, 223) 0 0 0 0 inset;
  transition: 0.3s ease-out all;
  -webkit-transition: 0.3s ease-out all;
  white-space: nowrap;
}
.switch small {
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  right: calc(100% - 30px);
  transition: 0.3s ease-out all;
  -webkit-transition: 0.3s ease-out all;
  background: #fff;
}
.switch.switch-small {
  height: 20px;
  border-radius: 20px;
}
.switch.switch-small small {
  width: 20px;
  height: 20px;
  right: calc(100% - 20px);
}
.switch.switch-small > .switch-pane > span {
  line-height: 20px;
}
.switch.switch-small > .switch-pane .switch-label-checked {
  padding-right: 25px;
  padding-left: 10px;
}
.switch.switch-small > .switch-pane .switch-label-unchecked {
  padding-left: 25px;
  padding-right: 10px;
}
.switch.switch-medium {
  height: 30px;
  border-radius: 30px;
}
.switch.switch-medium small {
  width: 30px;
  height: 30px;
  right: calc(100% - 30px);
}
.switch.switch-medium > .switch-pane > span {
  line-height: 30px;
}
.switch.switch-medium > .switch-pane .switch-label-checked {
  padding-right: 35px;
  padding-left: 15px;
}
.switch.switch-medium > .switch-pane .switch-label-unchecked {
  padding-left: 35px;
  padding-right: 15px;
}
.switch.switch-large {
  height: 40px;
  border-radius: 40px;
}
.switch.switch-large small {
  width: 40px;
  height: 40px;
  right: calc(100% - 40px);
}
.switch.switch-large > .switch-pane > span {
  line-height: 40px;
}
.switch.switch-large > .switch-pane .switch-label-checked {
  padding-right: 45px;
  padding-left: 20px;
}
.switch.switch-large > .switch-pane .switch-label-unchecked {
  padding-left: 45px;
  padding-right: 20px;
}
.switch.checked {
  background: rgb(100, 189, 99);
}
.switch.checked small {
  right: 0;
  left: auto;
}
.switch.checked .switch-pane {
  top: 0;
}
.switch.checked .switch-pane .switch-label-checked {
  opacity: 1;
}
.switch.checked .switch-pane .switch-label-unchecked {
  opacity: 0;
}
.switch.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.switch .switch-pane {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  justify-content: flex-start;
  align-items: center;
  top: -100%;
  position: relative;
  pointer-events: none;
}
.switch .switch-pane > span {
  display: block;
  min-height: 100%;
}
.switch .switch-pane .switch-label-checked {
  opacity: 0;
}
.switch .switch-pane .switch-label-unchecked {
  opacity: 1;
}

ngb-datepicker {
  background-color: #fff;
  position: absolute;
  z-index: 1000;
}

ngb-datepicker-navigation-select > .custom-select {
  margin: 0 5px;
}

.ngb-dp-day,
.ngb-dp-today {
  outline: none !important;
}
.ngb-dp-day .btn-light,
.ngb-dp-day .btn-primary,
.ngb-dp-today .btn-light,
.ngb-dp-today .btn-primary {
  box-shadow: none !important;
  outline: none !important;
  border-radius: 50%;
}

.ngb-dp-weekday {
  font-style: normal !important;
}

.ngb-tp {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  background-color: #fff;
  margin-top: 5px;
  float: left;
  min-width: 10rem;
  padding: 0.75rem;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #495057;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  position: absolute;
  z-index: 1000;
}

.custom-day {
  line-height: 2rem;
  border-radius: 50%;
  padding: 0 !important;
  outline: none !important;
}
.custom-day:hover {
  background-color: #556ee6 !important;
}
.custom-day.range {
  background-color: #556ee6 !important;
}
.custom-day.range.faded {
  background-color: white !important;
  color: #495057 !important;
}

.ck.ck-toolbar {
  background-color: #eff2f7 !important;
  border: 1px solid #f6f6f6 !important;
}

.ck.ck-editor__main > .ck-editor__editable {
  border-color: #f6f6f6 !important;
  box-shadow: none !important;
}

.ck.ck-dropdown__panel,
.ck.ck-list {
  background: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
}

.ck-editor__editable {
  min-height: 245px !important;
}

.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
  flex-wrap: wrap !important;
}

.ck.ck-editor__main > .ck-editor__editable {
  background-color: #fff !important;
}

.ck.ck-reset_all,
.ck.ck-reset_all * {
  color: #74788d !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: transparent !important;
}

.ck.ck-button.ck-on,
a.ck.ck-button.ck-on,
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: #eff2f7 !important;
}

/* Dropzone */
.dropzone {
  min-height: 230px;
  border: 2px dashed #ced4da !important;
  background: #fff !important;
  border-radius: 6px;
}
.dropzone .dropzone {
  border: none !important;
}
.dropzone .dz-message {
  font-size: 24px;
  min-height: 150px !important;
  border: none !important;
  background: #fff !important;
  color: #495057 !important;
}

.form-wizard-wrapper label {
  font-size: 14px;
  text-align: right;
}

.wizard ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
}
.wizard > .steps > ul > li {
  width: 25%;
}
.wizard > .steps .current-info {
  position: absolute;
  left: -999em;
}
.wizard > .steps a,
.wizard > .steps a:active,
.wizard > .steps a:hover {
  margin: 3px;
  padding: 15px;
  display: block;
  width: auto;
  border-radius: 5px;
}
.wizard > .steps .current a,
.wizard > .steps .current a:active,
.wizard > .steps .current a:hover {
  background-color: #556ee6;
  color: #fff;
}
.wizard > .steps .current a .number,
.wizard > .steps .current a:active .number,
.wizard > .steps .current a:hover .number {
  border: 2px solid #fff;
}
.wizard > .steps .disabled a,
.wizard > .steps .disabled a:active,
.wizard > .steps .disabled a:hover,
.wizard > .steps .done a,
.wizard > .steps .done a:active,
.wizard > .steps .done a:hover {
  background-color: #dae0fa;
  color: #556ee6;
}
.wizard > .steps .disabled a .number,
.wizard > .steps .disabled a:active .number,
.wizard > .steps .disabled a:hover .number,
.wizard > .steps .done a .number,
.wizard > .steps .done a:active .number,
.wizard > .steps .done a:hover .number {
  border-color: #556ee6;
}
.wizard > .steps .number {
  font-size: 16px;
  padding: 5px;
  border-radius: 50%;
  border: 2px solid #fff;
  width: 38px;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  margin-right: 10px;
  background-color: rgba(85, 110, 230, 0.25);
}
.wizard > .content {
  background-color: transparent;
  margin: 0 5px;
  border-radius: 0;
  min-height: 150px;
}
.wizard > .content > .title {
  position: absolute;
  left: -999em;
}
.wizard > .content > .body {
  width: 100%;
  height: 100%;
  padding: 30px 0 0;
  position: static;
}
.wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%;
}
.wizard > .actions > ul {
  display: inline-block;
  text-align: right;
}
.wizard > .actions > ul > li {
  display: block;
  margin: 0 0.5em;
}
.wizard > .actions a,
.wizard > .actions a:active,
.wizard > .actions a:hover {
  background-color: #556ee6;
  border-radius: 4px;
  padding: 8px 15px;
  color: #fff;
}
.wizard > .actions .disabled a,
.wizard > .actions .disabled a:active,
.wizard > .actions .disabled a:hover {
  opacity: 0.65;
  background-color: #556ee6;
  color: #fff;
  cursor: not-allowed;
}
.wizard > .steps > ul > li,
.wizard > .actions > ul > li {
  float: left;
}

@media (max-width: 768px) {
  .wizard > .steps > ul > li {
    width: 50%;
  }
  .form-wizard-wrapper label {
    text-align: left;
  }
}
@media (max-width: 520px) {
  .wizard > .steps > ul > li {
    width: 100%;
  }
}
.kyc-tab-content {
  padding-top: 24px;
  min-height: 262px;
}

.table-rep-plugin .btn-toolbar {
  display: block;
}
.table-rep-plugin .table-responsive {
  border: none !important;
}
.table-rep-plugin .btn-group .btn-default {
  background-color: #74788d;
  color: #fff;
  border: 1px solid #74788d;
}
.table-rep-plugin .btn-group .btn-default.btn-primary {
  background-color: #556ee6;
  border-color: #556ee6;
  color: #fff;
  box-shadow: 0 0 0 2px rgba(85, 110, 230, 0.5);
}
.table-rep-plugin .btn-group.pull-right {
  float: right;
}
.table-rep-plugin .btn-group.pull-right .dropdown-menu {
  right: 0;
  transform: none !important;
  top: 100% !important;
}
.table-rep-plugin tbody th {
  font-size: 14px;
  font-weight: normal;
}
.table-rep-plugin .checkbox-row {
  padding-left: 40px;
}
.table-rep-plugin .checkbox-row label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
}
.table-rep-plugin .checkbox-row label::before {
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #f6f6f6;
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 17px;
  outline: none !important;
}
.table-rep-plugin .checkbox-row label::after {
  color: #eff2f7;
  display: inline-block;
  font-size: 11px;
  height: 16px;
  left: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  position: absolute;
  top: -1px;
  width: 16px;
}
.table-rep-plugin .checkbox-row input[type=checkbox] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}
.table-rep-plugin .checkbox-row input[type=checkbox]:disabled + label {
  opacity: 0.65;
}
.table-rep-plugin .checkbox-row input[type=checkbox]:focus + label::before {
  outline-offset: -2px;
  outline: none;
}
.table-rep-plugin .checkbox-row input[type=checkbox]:checked + label::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.table-rep-plugin .checkbox-row input[type=checkbox]:disabled + label::before {
  background-color: #f8f9fa;
  cursor: not-allowed;
}
.table-rep-plugin .checkbox-row input[type=checkbox]:checked + label::before {
  background-color: #556ee6;
  border-color: #556ee6;
}
.table-rep-plugin .checkbox-row input[type=checkbox]:checked + label::after {
  color: #fff;
}
.table-rep-plugin table.focus-on tbody tr.focused th .sticky-table-header,
.table-rep-plugin table.focus-on tbody tr.focused td .sticky-table-header,
.table-rep-plugin .fixed-solution .sticky-table-header {
  background: #556ee6;
  border-color: #556ee6;
  color: #fff;
}
.table-rep-plugin table.focus-on tbody tr.focused th .sticky-table-header table,
.table-rep-plugin table.focus-on tbody tr.focused td .sticky-table-header table,
.table-rep-plugin .fixed-solution .sticky-table-header table {
  color: #fff;
}

.datatables thead tr th {
  position: relative;
}
.datatables thead tr th:before {
  content: "\f0143";
  position: absolute;
  right: 0;
  top: 7px;
  font: normal normal normal 24px/1 "Material Design Icons";
  opacity: 0.3;
}
.datatables thead tr th:after {
  content: "\f0140";
  position: absolute;
  right: 0;
  top: 14px;
  opacity: 0.3;
  font: normal normal normal 24px/1 "Material Design Icons";
}
.datatables thead tr .asc:before {
  opacity: 1;
}
.datatables thead tr .desc:after {
  opacity: 1;
}

.ct-golden-section:before {
  float: none;
}

.ct-chart {
  max-height: 300px;
}
.ct-chart .ct-label {
  fill: #adb5bd !important;
  color: #343a40 !important;
  font-size: 12px;
  line-height: 1;
}

.ct-chart.simple-pie-chart-chartist .ct-label {
  color: #fff !important;
  fill: #fff !important;
  font-size: 16px;
}

:not(.without-styles) .ct-series-a .ct-slice-donut {
  stroke: #556ee6 !important;
}

:not(.without-styles) .ct-series-b .ct-slice-donut {
  stroke: #34c38f !important;
}

:not(.without-styles) .ct-series-c .ct-slice-donut {
  stroke: #f1b44c !important;
}

:not(.without-styles) .ct-series-d .ct-slice-donut {
  stroke: #f46a6a !important;
}

:not(.without-styles) .ct-series-e .ct-slice-donut {
  stroke: #50a5f1 !important;
}

:not(.without-styles) .ct-series-f .ct-slice-donut {
  stroke: #e83e8c !important;
}

:not(.without-styles) .ct-series-g .ct-slice-donut {
  stroke: #556ee6 !important;
}

.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
  stroke: #556ee6 !important;
}
.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
  stroke: #34c38f !important;
}
.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
  stroke: #f1b44c !important;
}
.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
  stroke: #f46a6a !important;
}
.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
  stroke: #50a5f1 !important;
}
.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
  stroke: #e83e8c !important;
}
.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
  stroke: #556ee6 !important;
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: #556ee6 !important;
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: #34c38f !important;
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: #f1b44c !important;
}

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
  fill: #f46a6a !important;
}

.ct-area {
  fill-opacity: 0.33;
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 10px;
  padding: 2px 10px;
  border-radius: 3px;
  background: #343a40;
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  transition: opacity 0.2s linear;
}
.chartist-tooltip.tooltip-show {
  opacity: 1;
}

:not(.without-styles) .ct-series-a .ct-line,
:not(.without-styles) .ct-series-a .ct-point,
:not(.without-styles) .ct-series-a .ct-bar {
  stroke: #556ee6 !important;
}

:not(.without-styles) .ct-series-b .ct-line,
:not(.without-styles) .ct-series-b .ct-point,
:not(.without-styles) .ct-series-b .ct-bar {
  stroke: #1abc9c !important;
}

:not(.without-styles) .ct-series-c .ct-line,
:not(.without-styles) .ct-series-c .ct-point {
  stroke: #f7b84b !important;
}

.apex-charts {
  min-height: 10px !important;
}
.apex-charts text {
  font-family: "Poppins", sans-serif !important;
  fill: #adb5bd;
}
.apex-charts .apexcharts-canvas {
  margin: 0 auto;
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
  font-family: "Poppins", sans-serif !important;
}

.apexcharts-legend-series {
  font-weight: 500;
}

.apexcharts-gridline {
  pointer-events: none;
  stroke: #f8f9fa;
}

.apexcharts-legend-text {
  color: #74788d !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 13px !important;
}

.apexcharts-pie-label {
  fill: #fff !important;
}

.apexcharts-yaxis text,
.apexcharts-xaxis text {
  font-family: "Poppins", sans-serif !important;
  fill: #adb5bd;
}

.gmaps, .gmaps-panaroma {
  height: 300px;
  background: #f8f9fa;
  border-radius: 3px;
}

.gmaps-overlay {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  background: #556ee6;
  border-radius: 4px;
  padding: 10px 20px;
}

.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}
.gmaps-overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #556ee6;
}
.gmaps-overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #556ee6;
}

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden; /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item.center {
  z-index: 5;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

.navs-carousel .owl-nav {
  margin-top: 16px;
}
.navs-carousel .owl-nav .owl-next, .navs-carousel .owl-nav .owl-prev {
  width: 30px;
  height: 30px;
  line-height: 24px !important;
  font-size: 20px !important;
  border-radius: 50% !important;
  background-color: rgba(85, 110, 230, 0.25) !important;
  color: #556ee6 !important;
  margin: 4px 8px !important;
  padding: 4px 5px !important;
}

.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

.search-box .form-control {
  border-radius: 30px;
  padding-left: 40px;
}
.search-box .search-icon {
  font-size: 16px;
  position: absolute;
  left: 13px;
  top: 0;
  line-height: 38px;
}

.product-list li a {
  display: block;
  padding: 4px 0px;
  color: #495057;
}

.product-view-nav.nav-pills .nav-item {
  margin-left: 4px;
}
.product-view-nav.nav-pills .nav-link {
  width: 36px;
  height: 36px;
  font-size: 16px;
  padding: 0;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
}

.product-ribbon {
  position: absolute;
  right: 0px;
  top: 0px;
}

.product-detai-imgs .nav .nav-link {
  margin: 7px 0px;
}
.product-detai-imgs .nav .nav-link.active {
  background-color: #f6f6f6;
}

.product-color a {
  display: inline-block;
  text-align: center;
  color: #495057;
}
.product-color a .product-color-item {
  margin: 7px;
}
.product-color a.active, .product-color a:hover {
  color: #556ee6;
}
.product-color a.active .product-color-item, .product-color a:hover .product-color-item {
  border-color: #556ee6 !important;
}

.visa-card .visa-logo {
  line-height: 0.5;
}
.visa-card .visa-pattern {
  position: absolute;
  font-size: 385px;
  color: rgba(255, 255, 255, 0.05);
  line-height: 0.4;
  right: 0px;
  bottom: 0px;
}

.checkout-tabs .nav-pills .nav-link {
  margin-bottom: 24px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.checkout-tabs .nav-pills .nav-link.active {
  background-color: #556ee6;
}
.checkout-tabs .nav-pills .nav-link .check-nav-icon {
  font-size: 36px;
}

/* ==============
  Email
===================*/
.email-leftbar {
  width: 236px;
  float: left;
  padding: 20px;
  border-radius: 5px;
}

.email-rightbar {
  margin-left: 260px;
}

.chat-user-box p.user-title {
  color: #343a40;
  font-weight: 500;
}
.chat-user-box p {
  font-size: 12px;
}

@media (max-width: 767px) {
  .email-leftbar {
    float: none;
    width: 100%;
  }
  .email-rightbar {
    margin: 0;
  }
}
.mail-list a {
  display: block;
  color: #74788d;
  line-height: 24px;
  padding: 8px 5px;
}
.mail-list a.active {
  color: #f46a6a;
  font-weight: 500;
}

.message-list {
  display: block;
  padding-left: 0;
}
.message-list li {
  position: relative;
  display: block;
  height: 50px;
  line-height: 50px;
  cursor: default;
  transition-duration: 0.3s;
}
.message-list li a {
  color: #74788d;
}
.message-list li:hover {
  background: #f6f6f6;
  transition-duration: 0.05s;
}
.message-list li .col-mail {
  float: left;
  position: relative;
}
.message-list li .col-mail-1 {
  width: 320px;
}
.message-list li .col-mail-1 .star-toggle,
.message-list li .col-mail-1 .checkbox-wrapper-mail,
.message-list li .col-mail-1 .dot {
  display: block;
  float: left;
}
.message-list li .col-mail-1 .dot {
  border: 4px solid transparent;
  border-radius: 100px;
  margin: 22px 26px 0;
  height: 0;
  width: 0;
  line-height: 0;
  font-size: 0;
}
.message-list li .col-mail-1 .checkbox-wrapper-mail {
  margin: 15px 10px 0 20px;
}
.message-list li .col-mail-1 .star-toggle {
  margin-top: 18px;
  margin-left: 5px;
}
.message-list li .col-mail-1 .title {
  position: absolute;
  top: 0;
  left: 110px;
  right: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
}
.message-list li .col-mail-2 {
  position: absolute;
  top: 0;
  left: 320px;
  right: 0;
  bottom: 0;
}
.message-list li .col-mail-2 .subject,
.message-list li .col-mail-2 .date {
  position: absolute;
  top: 0;
}
.message-list li .col-mail-2 .subject {
  left: 0;
  right: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.message-list li .col-mail-2 .date {
  right: 0;
  width: 170px;
  padding-left: 80px;
}
.message-list li.active, .message-list li.active:hover {
  box-shadow: inset 3px 0 0 #556ee6;
}
.message-list li.unread {
  background-color: #f6f6f6;
  font-weight: 500;
  color: #292d32;
}
.message-list li.unread a {
  color: #292d32;
  font-weight: 500;
}
.message-list .checkbox-wrapper-mail {
  cursor: pointer;
  height: 20px;
  width: 20px;
  position: relative;
  display: inline-block;
  box-shadow: inset 0 0 0 1px #ced4da;
  border-radius: 1px;
}
.message-list .checkbox-wrapper-mail input {
  opacity: 0;
  cursor: pointer;
}
.message-list .checkbox-wrapper-mail input:checked ~ label {
  opacity: 1;
}
.message-list .checkbox-wrapper-mail label {
  position: absolute;
  height: 20px;
  width: 20px;
  left: 0;
  cursor: pointer;
  opacity: 0;
  margin-bottom: 0;
  transition-duration: 0.05s;
  top: 0;
}
.message-list .checkbox-wrapper-mail label:before {
  content: "\f012c";
  font-family: "Material Design Icons";
  top: 0;
  height: 20px;
  color: #292d32;
  width: 20px;
  position: absolute;
  margin-top: -16px;
  left: 4px;
  font-size: 13px;
}

@media (max-width: 575.98px) {
  .message-list li .col-mail-1 {
    width: 200px;
  }
}
.project-list-table {
  border-collapse: separate;
  border-spacing: 0 12px;
}
.project-list-table tr {
  background-color: #fff;
}

.contact-links a {
  color: #495057;
}

.profile-user-wid {
  margin-top: -26px;
}

.counter-number {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  display: flex;
}
.counter-number span {
  font-size: 16px;
  font-weight: 400;
  display: block;
  padding-top: 5px;
}

.coming-box {
  width: 25%;
}

/************** Horizontal timeline **************/
.hori-timeline .events .event-list {
  text-align: center;
  display: block;
}
.hori-timeline .events .event-list .event-down-icon {
  position: relative;
}
.hori-timeline .events .event-list .event-down-icon::before {
  content: "";
  position: absolute;
  width: 100%;
  top: 16px;
  left: 0;
  right: 0;
  border-bottom: 3px dashed #f6f6f6;
}
.hori-timeline .events .event-list .event-down-icon .down-arrow-icon {
  position: relative;
  background-color: #fff;
  padding: 4px;
}
.hori-timeline .events .event-list:hover .down-arrow-icon {
  animation: fade-down 1.5s infinite linear;
}
.hori-timeline .events .event-list.active .down-arrow-icon {
  animation: fade-down 1.5s infinite linear;
}
.hori-timeline .events .event-list.active .down-arrow-icon:before {
  content: "\ec4c";
}

/************** vertical timeline **************/
.verti-timeline {
  border-left: 3px dashed #f6f6f6;
  margin: 0 10px;
}
.verti-timeline .event-list {
  position: relative;
  padding: 0px 0px 40px 30px;
}
.verti-timeline .event-list .event-timeline-dot {
  position: absolute;
  left: -9px;
  top: 0px;
  z-index: 9;
  font-size: 16px;
}
.verti-timeline .event-list .event-content {
  position: relative;
  border: 2px solid #eff2f7;
  border-radius: 7px;
}
.verti-timeline .event-list.active .event-timeline-dot {
  color: #556ee6;
}
.verti-timeline .event-list:last-child {
  padding-bottom: 0px;
}

.plan-box .plan-btn {
  position: relative;
}
.plan-box .plan-btn::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #f6f6f6;
  left: 0px;
  right: 0px;
  top: 12px;
}

@media (min-width: 992px) {
  .chat-leftsidebar {
    min-width: 380px;
  }
}
.chat-leftsidebar .chat-leftsidebar-nav .nav {
  background-color: #fff;
}
.chat-leftsidebar .chat-leftsidebar-nav .tab-content {
  min-height: 488px;
}

.chat-noti-dropdown.active:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #f46a6a;
  border-radius: 50%;
  right: 0;
}
.chat-noti-dropdown .btn {
  padding: 6px;
  box-shadow: none;
  font-size: 20px;
}

.chat-search-box .form-control {
  border: 0;
}

.chat-list {
  margin: 0;
}
.chat-list li.active a {
  background-color: #fff;
  border-color: transparent;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.chat-list li a {
  display: block;
  padding: 14px 16px;
  color: #74788d;
  transition: all 0.4s;
  border-top: 1px solid #eff2f7;
  border-radius: 4px;
}
.chat-list li a:hover {
  background-color: #fff;
  border-color: transparent;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.user-chat-nav .dropdown .nav-btn {
  height: 40px;
  width: 40px;
  line-height: 40px;
  box-shadow: none;
  padding: 0;
  font-size: 16px;
  background-color: #eff2f7;
  border-radius: 50%;
}
.user-chat-nav .dropdown .dropdown-menu {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border: 1px solid #eff2f7;
}

.chat-conversation li {
  clear: both;
}
.chat-conversation .chat-day-title {
  position: relative;
  text-align: center;
  margin-bottom: 24px;
}
.chat-conversation .chat-day-title .title {
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding: 6px 24px;
}
.chat-conversation .chat-day-title:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  background-color: #eff2f7;
  top: 10px;
}
.chat-conversation .chat-day-title .badge {
  font-size: 12px;
}
.chat-conversation .conversation-list {
  margin-bottom: 24px;
  display: inline-block;
  position: relative;
}
.chat-conversation .conversation-list .ctext-wrap {
  padding: 12px 24px;
  background-color: rgba(85, 110, 230, 0.1);
  border-radius: 8px 8px 8px 0px;
  overflow: hidden;
}
.chat-conversation .conversation-list .ctext-wrap .conversation-name {
  font-weight: 600;
  color: #556ee6;
  margin-bottom: 4px;
}
.chat-conversation .conversation-list .dropdown {
  float: right;
}
.chat-conversation .conversation-list .dropdown .dropdown-toggle {
  font-size: 18px;
  padding: 4px;
  color: #74788d;
}
@media (max-width: 575.98px) {
  .chat-conversation .conversation-list .dropdown .dropdown-toggle {
    display: none;
  }
}
.chat-conversation .conversation-list .dropdown .dropdown-menu {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border: 1px solid #eff2f7;
}
.chat-conversation .conversation-list .chat-time {
  font-size: 12px;
}
.chat-conversation .right .conversation-list {
  float: right;
}
.chat-conversation .right .conversation-list .ctext-wrap {
  background-color: #eff2f7;
  text-align: right;
  border-radius: 8px 8px 0px 8px;
}
.chat-conversation .right .conversation-list .dropdown {
  float: left;
}
.chat-conversation .right .conversation-list.last-chat .conversation-list:before {
  right: 0;
  left: auto;
}
.chat-conversation .last-chat .conversation-list:before {
  content: "\f0009";
  font-family: "Material Design Icons";
  position: absolute;
  color: #556ee6;
  right: 0;
  bottom: 0;
  font-size: 16px;
}
@media (max-width: 575.98px) {
  .chat-conversation .last-chat .conversation-list:before {
    display: none;
  }
}

.chat-input-section {
  border-top: 1px solid #eff2f7;
}

.chat-input {
  border-radius: 30px;
  background-color: #eff2f7 !important;
  border-color: #eff2f7 !important;
  padding-right: 120px;
}

.chat-input-links {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.chat-input-links li a {
  font-size: 16px;
  line-height: 36px;
  padding: 0px 4px;
  display: inline-block;
}

@media (max-width: 575.98px) {
  .chat-send {
    min-width: auto;
  }
}

@media (min-width: 576px) {
  .currency-value {
    position: relative;
  }
  .currency-value:after {
    content: "\f04e1";
    font-family: "Material Design Icons";
    font-size: 24px;
    position: absolute;
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 50%;
    text-align: center;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #556ee6;
    color: #fff;
    z-index: 9;
    right: -34px;
  }
}
.crypto-buy-sell-nav-content {
  border: 2px solid #f6f6f6;
  border-top: 0;
}

.kyc-doc-verification .dropzone {
  padding: 0px !important;
}
.kyc-doc-verification .dropzone .dz-message {
  margin: 24px 0px;
}

/******************
    Ico Landing
*******************/
.section {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
}
.section.bg-white {
  background-color: #fff !important;
}

.small-title {
  color: #74788d;
  margin-bottom: 8px;
}

.navigation {
  padding: 0 16px;
  width: 100%;
  z-index: 999;
  margin-bottom: 0px;
  transition: all 0.5s ease-in-out;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 991.98px) {
  .navigation {
    background-color: #fff;
  }
}
.navigation .navbar-logo {
  line-height: 70px;
  transition: all 0.4s;
}
.navigation .navbar-logo .logo-dark {
  display: none;
}
@media (max-width: 991.98px) {
  .navigation .navbar-logo .logo-dark {
    display: block;
  }
}
.navigation .navbar-logo .logo-light {
  display: block;
}
@media (max-width: 991.98px) {
  .navigation .navbar-logo .logo-light {
    display: none;
  }
}
.navigation .navbar-nav .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.6);
  line-height: 58px;
  padding: 6px 16px;
  font-weight: 500;
  transition: all 0.4s;
}
@media (max-width: 991.98px) {
  .navigation .navbar-nav .nav-item .nav-link {
    color: black;
  }
}
.navigation .navbar-nav .nav-item .nav-link:hover, .navigation .navbar-nav .nav-item .nav-link.active {
  color: rgba(255, 255, 255, 0.9);
}
@media (max-width: 991.98px) {
  .navigation .navbar-nav .nav-item .nav-link:hover, .navigation .navbar-nav .nav-item .nav-link.active {
    color: #556ee6;
  }
}
@media (max-width: 991.98px) {
  .navigation .navbar-nav .nav-item .nav-link {
    line-height: 28px !important;
  }
}
.navigation.nav-sticky {
  background-color: #fff;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.navigation.nav-sticky .navbar-logo {
  line-height: 60px;
}
.navigation.nav-sticky .navbar-logo .logo-dark {
  display: block;
}
.navigation.nav-sticky .navbar-logo .logo-light {
  display: none;
}
.navigation.nav-sticky .navbar-nav .nav-item .nav-link {
  line-height: 48px;
  color: black;
}
.navigation.nav-sticky .navbar-nav .nav-item .nav-link:hover, .navigation.nav-sticky .navbar-nav .nav-item .nav-link.active {
  color: #556ee6;
}

.bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 0.7;
  background-color: #000;
}

.hero-section {
  padding-top: 220px;
  padding-bottom: 190px;
}
.hero-section.bg-ico-hero {
  background-size: cover;
  background-position: top;
}
@media (max-width: 575.98px) {
  .hero-section {
    padding-top: 140px;
    padding-bottom: 80px;
  }
}
.hero-section .hero-title {
  font-size: 42px;
}
@media (max-width: 575.98px) {
  .hero-section .hero-title {
    font-size: 26px;
  }
}
.hero-section .ico-countdown {
  font-size: 22px;
  margin-right: -12px;
  margin-left: -12px;
}
@media (max-width: 575.98px) {
  .hero-section .ico-countdown {
    display: block;
  }
}
.hero-section .ico-countdown .coming-box {
  margin-right: 12px;
  margin-left: 12px;
  border: 1px solid #eff2f7;
  border-radius: 4px;
  padding: 8px;
  background-color: #fff;
}
@media (max-width: 575.98px) {
  .hero-section .ico-countdown .coming-box {
    display: inline-block;
    width: 40%;
    margin-bottom: 24px;
  }
}
.hero-section .ico-countdown .coming-box span {
  background-color: #eff2f7;
  font-size: 12px;
  padding: 4px;
  margin-top: 8px;
}
.hero-section .softcap-progress {
  overflow: visible;
}
.hero-section .softcap-progress .progress-bar {
  overflow: visible;
}
.hero-section .softcap-progress .progress-label {
  position: relative;
  text-align: right;
  color: #495057;
  bottom: 20px;
  font-size: 12px;
  font-weight: 500;
}

.currency-price {
  position: relative;
  bottom: 40px;
}

.client-images img {
  max-height: 34px;
  width: auto !important;
  margin: 12px auto;
  opacity: 0.7;
  transition: all 0.4s;
}

.features-number {
  opacity: 0.1;
}

.team-box .team-social-links a {
  color: #495057;
  font-size: 14px;
}

.blog-box .blog-badge {
  position: absolute;
  top: 12px;
  right: 12px;
}

.landing-footer {
  padding: 80px 0 40px;
  background-color: #FAFAFA;
  color: rgba(255, 255, 255, 0.5);
}
.landing-footer .footer-list-title {
  color: rgba(255, 255, 255, 0.9);
}
.landing-footer .footer-list-menu li a {
  display: block;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 14px;
  transition: all 0.4s;
}
.landing-footer .footer-list-menu li a:hover {
  color: rgba(255, 255, 255, 0.8);
}
.landing-footer .blog-post .post {
  display: block;
  color: rgba(255, 255, 255, 0.5);
  padding: 16px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.landing-footer .blog-post .post .post-title {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}
.landing-footer .blog-post .post:first-of-type {
  padding-top: 0;
}
.landing-footer .blog-post .post:last-of-type {
  padding-bottom: 0;
  border-bottom: 0;
}
.landing-footer .footer-border {
  border-color: rgba(255, 255, 255, 0.1);
}

aw-wizard .steps-indicator .label {
  color: #74788d !important;
}
aw-wizard .steps-indicator .current .step-indicator {
  color: #556ee6 !important;
  border-color: #556ee6 !important;
}